import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import InputAdornment from "@mui/material/InputAdornment";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import SaveIcon from "@mui/icons-material/Save";
import NotInterestedRoundedIcon from '@mui/icons-material/NotInterestedRounded';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade'; 
import { InputsLabel, InputMessage, InputAutocomplete, InputCountryStateCity, CarpetAreaInput, PricingInput, InputMultiAutocomplete } from "../../Pages/Common"; 
import { setLead, getLead } from "./ApiCalls";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./EditLead.css";
import Cookies from 'js-cookie';
import { InputEmail, IntlDropdown } from "../../Pages/Common";

const EditLead = () => {

  const navigate = useNavigate();
  // const queryParams = new URLSearchParams(window.location.search); 
  const lead_id = useParams(); 
  const [leadsDetails, setLeadsDetails] = useState('');
  const [clientpershow, setclientpersShow] = useState(true);
  const [source_type, setSourceType] = useState(null);
  const [clientreqshow, setclientreqShow] = useState(true);
  const [prjdetailsshow, setprjdetailsShow] = useState(true);
  
  const [lccode, setLccode] = useState('');
  const [lmobile, setLmobile] = useState('');
  const [ref_ccode, setRefCcode] = useState('');
  const [ref_number, setRefNumber] = useState('');

  // const refname = useRef(null);
  const sccode = useRef(null);
  const snumber = useRef(null);
  // const refemail = useRef(null); 
  // const refrancename = useRef(null);
  // const refrancecode = useRef(null);
  // const refrancenum = useRef(null);
  // const refranceemail = useRef(null);
  const refform = useRef(null);

  // const [nameError, setNameError] = useState(false);
  // const [numError, setNumError] = useState(false);
  // const [emailError, setEmailError] = useState(false);
  // const [refNumError, setRefNumError] = useState(false);
  
  // Tooltip
  const [tooltipopen, setTooltipOpen] = useState(false);
  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };
  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };
  
  // const validateForm = (valid) => {
  //   let validForm = valid;
    // lead name
    // const leadname = refname.current;
    // const nameregExp = /[a-zA-Z]/g;
    // if(!nameregExp.test(leadname.value) || leadname.value.length === 0 || leadname.value === ''){
    //   window.scrollTo(0, 0);
    //   setNameError(true); 
    //   validForm = false;
    // }else{
    //   setNameError(false);  
    // }
    
    // lead number
    // const ccode = refccode.current;
    // const number = refnumber.current; 
    // if(parseInt(ccode.value) === 0 || parseInt(number.value) === 0 || number.value === ''){
    //   window.scrollTo(0, 0);
    //   setNumError(true);
    //   validForm = false;  
    // }else{ 
    //   setNumError(false);
    //   validForm = true; 
    // }
    
    // lead email
    // const email = refemail.current;
    // if(email.value.length === 0 || email.value === ''){
    //   window.scrollTo(0, 0);
    //   setEmailError(true); 
    //   validForm = false;
    // }else{ 
    //   setEmailError(false);  
    // } 
    
    // refrence
    // const reflname = refrancename.current;
    // const reflcode = refrancecode.current;
    // const reflnum = refrancenum.current;
    // const reflemail = refranceemail.current;
    // if(source_type === 'Reference'){
    //   if(parseInt(reflcode.value) === 0 || parseInt(reflnum.value) === 0 || reflnum.value === ''){
    //     setRefNumError(true); 
    //     validForm = false;
    //   }else{
    //     setRefNumError(false); 
    //     // validForm = true;
    //   }      
    // } 

    // const reflform = refform.current;
    // const data = new FormData(reflform);

  //   return validForm;
  // }; 
  
  const handleSubmit = (event) => {
    event.preventDefault();
    // if(validateForm() === true){
    const data = new FormData(event.currentTarget); 
    setLead(data, lead_id, lccode, lmobile, ref_ccode, ref_number)
    .then((response) => { 
      notify("Lead is updated.");
      setTimeout(() => {
        navigate("/viewlead/"+lead_id.leadId);
      }, 1000);
    })
    .catch((err) => {
      console.log(err);
    });
    // } else {
    //   validateForm(false);
    //   console.log('Error submitting form.'); 
    // } 
  };
  
  const notify = (msg) => toast(msg, {
    position: "bottom-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

  useEffect(() => {
    getLead(lead_id)
    .then((response) => { 
      setLeadsDetails(response.data[0]);
      setLccode(response.data[0].p_ccode);
      setLmobile(response.data[0].p_mob);
      setRefCcode(response.data[0].ref_ccode);
      setRefNumber(response.data[0].ref_mob);
      setSourceType(response.data[0].source_type ? response.data[0].source_type : null);
    })
    .catch((err) => {
      console.log(err);
    });  
  }, [lead_id]);

  return (
    
    <Fade in timeout={800}>
      <div className="createstatus-container">
        <div className="create-status-header" style={{ position: 'fixed'}}>
          <Button size="small" className="close-btn" component={Link} to={"/viewlead/"+lead_id.leadId}>
            <KeyboardBackspaceIcon />
          </Button>
          <h3>
            Edit Lead : {lead_id.leadId}
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={tooltipopen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={
                  <React.Fragment>
                    <Typography color="inherit">Special characters not allowed.</Typography>
                    <em><br />{"Example: ` ~ ! # $ % ^ + = | } { > < [ ] /"}</em>
                  </React.Fragment>
                } //"special character are not allowed"
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                placement="bottom-start" 
                arrow
              > 
                <QuestionMarkIcon
                  sx={{position: 'absolute', top: '0.7rem', right: '1rem', color: '#17a2b8'}}
                  onClick={handleTooltipOpen}
                />
              </Tooltip>
            </ClickAwayListener>
          </h3>
        </div>
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          style={{paddingTop: '3.5rem'}}
          ref={refform}
          id="leadform"
        >
          <Grid 
            style={{
              background: "#fff",
            }}
            container
          >
            <Grid item={true} xs={12}>
              <Button
                sx={{ p: 1.6 }}
                className="lead-moredetails-btn"
                onClick={() => setclientpersShow(!clientpershow)}
              >
                <div className="add-leadmore">Client Personal Details</div>
                {clientpershow ? (
                  <KeyboardArrowUpIcon className="add-leadmore-icon" />
                ) : (
                  <KeyboardArrowDownIcon className="add-leadmore-icon" />
                )}
              </Button>  
            </Grid> 
            {clientpershow ? 
              <React.Fragment>
                <Divider variant="hr" sx={{width: '100%'}} />
                <Grid
                  mt={3}
                  pl={2}
                  pr={2}
                  style={{
                    background: "#fff",
                  }}
                  container
                >
                  {/* <Grid item={true} xs={12}>
                    {nameError === false ?
                      <InputsLabel InputsLabel="Enter Lead Name*" />
                    : 
                      <InputErrorMessage errormsg="name is required." />
                    }
                  </Grid>  */}
                  <Grid item={true} xs={12}>
                    {/* <AddLeadInput
                      InputLabel="alphabets & space only"
                      InputType="search"
                      InputName="lname"
                      Required={nameError === false ? false : true}
                      inputRef={refname}
                      inputId="leadname"
                      defautValue={leadsDetails.lname ? leadsDetails.lname : null}
                    /> */}
                    <TextField
                      // disabled = {Cookies.get('role') === 'Master' ? false : true}
                      readonly
                      id="outlined-disabled"
                      label="Lead Name"
                      name="lname"
                      value={leadsDetails.lname ? leadsDetails.lname : 'Undefined'}
                      sx={{width: '100%'}}
                      className="csttextfield"
                      InputProps={{
                        endAdornment: <InputAdornment position="end"><NotInterestedRoundedIcon sx={{color: '#303030', fontSize: '14px'}} /></InputAdornment>,
                      }}
                      // helperText="Permission not granted."
                    />
                  </Grid>
                </Grid>
                {/* <Divider variant="inset" sx={{ ml: 0, width: '100%'}} /> */}
                <Grid
                  mt={2}
                  pl={2}
                  pr={2}
                  style={{
                    background: "#fff",
                  }}
                  container
                >  
                  {/* <Grid item={true} xs={12}> 
                    {numError === false ?
                      <InputsLabel InputsLabel="Enter Mobile No*" />
                    : 
                      <InputErrorMessage errormsg="invalid mobile no." />
                    }
                  </Grid>  */}
                  <Grid item={true} xs={12}>
                    {/* <IntlDropdown 
                      Inputname={["lccode", "lmobile"]} 
                      InputRequired={true} 
                      InputRef={[refccode, refnumber]} 
                      InputId={['leadccode', 'leadnumber']}
                      defautValue={[
                        leadsDetails.p_ccode ? leadsDetails.p_ccode : null,
                        leadsDetails.p_mob ? leadsDetails.p_mob : null
                      ]}
                    /> */}
                    <TextField
                      disabled
                      id="outlined-disabled"
                      label="Lead Number"
                      // name="lname" 
                      value={leadsDetails.p_mob ? leadsDetails.p_ccode+' '+leadsDetails.p_mob : 'Undefined'}
                      sx={{width: '100%'}}
                      className="csttextfield"
                      InputProps={{
                        endAdornment: <InputAdornment position="end"><NotInterestedRoundedIcon sx={{color: '#303030', fontSize: '14px'}} /></InputAdornment>,
                      }}
                    />
                  </Grid> 
                </Grid> 
                {/* <Divider variant="inset" sx={{ ml: 0, width: '100%'}} /> */}
                <Grid
                  mt={2}
                  pl={2}
                  pr={2}
                  style={{
                    background: "#fff",
                  }}
                  container
                > 
                  {/* <Grid item={true} xs={12}> 
                    {emailError === false ?
                      <InputsLabel InputsLabel="Enter Email Id*" />
                    : 
                      <InputErrorMessage errormsg="email id is required." />
                    }
                  </Grid>  */}
                  <Grid item={true} xs={12}>
                    {/* <InputEmail
                      InputLabel="alphabets, numbers, @, -, . only"
                      InputType="email"
                      InputName="lemail"
                      Required={emailError === false ? false : true}
                      InputRef={refemail}
                      InputId="leademail"
                      defautValue={leadsDetails.p_email ? leadsDetails.p_email : null}
                    /> */}
                    <TextField
                      readonly
                      id="outlined-disabled"
                      label="Lead Email Id"
                      name="lemail" 
                      value={leadsDetails.p_email ? leadsDetails.p_email : 'Undefined'}
                      sx={{width: '100%'}}
                      className="csttextfield"
                      InputProps={{
                        endAdornment: <InputAdornment position="end"><NotInterestedRoundedIcon sx={{color: '#303030', fontSize: '14px'}} /></InputAdornment>,
                      }}
                    />
                  </Grid>
                </Grid>
                {/* <Divider variant="inset" sx={{ ml: 0, width: '100%'}} /> */}
                <Grid
                  mt={2}
                  pl={2}
                  pr={2}
                  pb={2}
                  style={{
                    background: "#fff",
                  }}
                  container
                >  
                  <Grid item={true} xs={12} >
                    <InputAutocomplete 
                      inputLable="Service type"
                      sourcetype="Service Type"
                      services={["New Lead", "Resale", "Rent"]}
                      inputname="service_type"
                      inputRequired={true}
                      defautValue={leadsDetails.service_type ? leadsDetails.service_type : null}
                    />
                  </Grid>  
                  <Grid item={true} mt={2} xs={12} > 
                    <TextField
                      readonly
                      id="outlined-disabled"
                      label="Source type"
                      name="source_type" 
                      value={leadsDetails.source_type ? leadsDetails.source_type : 'Undefined'}
                      sx={{width: '100%'}}
                      className="csttextfield"
                      InputProps={{
                        endAdornment: <InputAdornment position="end"><NotInterestedRoundedIcon sx={{color: '#303030', fontSize: '14px'}} /></InputAdornment>,
                      }}
                    />
                    {/* <FormControl variant="outlined" fullWidth> 
                      <InputLabel id="demo-simple-select-label">Source type*</InputLabel>
                      <Select
                        label="Source type*"
                        id="demo-simple-select-outlined"
                        name="source_type" 
                        className="inputautocompletemenuchange"
                        required={true}
                        value={source_type}
                        onChange={(event) => setSourceType(event.target.value)}
                      > 
                        {["Direct", "Reference", "Broker"]?.map((v, i) => (
                          <MenuItem value={v} key={i}>{v}</MenuItem>
                        ))}
                      </Select>
                    </FormControl> */}
                  </Grid> 
                  {/* Dynamic menu */}
                  {source_type === "Direct" ? 
                    <React.Fragment>
                      <Grid item={true} mt={2} xs={12} >
                        {/* <InputAutocomplete 
                          inputLable="Source"
                          sourcetype="Direct"
                          directServices={["Google", "Facebook", "Housing"]}
                          inputname="source"
                          inputRequired={true}
                          defautValue={leadsDetails.source ? leadsDetails.source : null}
                        /> */}
                        <TextField
                          readonly
                          id="outlined-disabled"
                          label="Source"
                          name="source" 
                          value={leadsDetails.source ? leadsDetails.source : 'Undefined'}
                          sx={{width: '100%'}}
                          className="csttextfield"
                          InputProps={{
                            endAdornment: <InputAdornment position="end"><NotInterestedRoundedIcon sx={{color: '#303030', fontSize: '14px'}} /></InputAdornment>,
                          }}
                        />
                      </Grid>    
                    </React.Fragment> 
                  : source_type === "Reference" ? 
                    <React.Fragment> 
                      <Grid item={true} mt={2} pb={1} xs={12}>
                        {/* <AddLeadInput
                          InputLabel="Reference Name"
                          InputType="search"
                          InputName="ref_name"
                          Required={true}
                          inputRef={refrancename}
                          inputId="refleadname"
                          defautValue={leadsDetails.ref_name ? leadsDetails.ref_name : null}
                        /> */}
                        <TextField
                          readonly
                          id="outlined-disabled"
                          label="Reference Name"
                          name="ref_name" 
                          value={leadsDetails.ref_name ? leadsDetails.ref_name : 'Undefined'}
                          sx={{width: '100%'}}
                          className="csttextfield"
                          InputProps={{
                            endAdornment: <InputAdornment position="end"><NotInterestedRoundedIcon sx={{color: '#303030', fontSize: '14px'}} /></InputAdornment>,
                          }}
                        />
                      </Grid> 
                      <Grid item={true} mt={1} xs={12}>
                        {/* {refNumError === false ? null : 
                          <InputErrorMessage errormsg="number is invalid." />
                        } */}
                        {/* <IntlDropdown 
                          Inputname={["ref_ccode", "ref_number"]} 
                          InputRequired={refNumError === false ? false : true}  
                          InputRef={[refrancecode, refrancenum]}
                          InputId={['reflcode', 'reflnum']}
                          defautValue={[
                            leadsDetails.ref_ccode ? leadsDetails.ref_ccode : null,
                            leadsDetails.ref_mob ? leadsDetails.ref_mob : null
                          ]}
                          inputDisabled={true}
                        />  */}
                        <TextField
                          disabled
                          id="outlined-disabled"
                          label="Reference Number"
                          // name="ref_ccode" // ref_number
                          value={leadsDetails.ref_mob ? leadsDetails.ref_ccode+' '+leadsDetails.ref_mob : 'Undefined'}
                          sx={{width: '100%'}}
                          className="csttextfield"
                          InputProps={{
                            endAdornment: <InputAdornment position="end"><NotInterestedRoundedIcon sx={{color: '#303030', fontSize: '14px'}} /></InputAdornment>,
                          }}
                        />
                      </Grid> 
                      <Grid item={true} mt={1} pt={1} pb={1} xs={12}>
                        <TextField
                          readonly
                          id="outlined-disabled"
                          label="Reference Email Id"
                          name="ref_email" 
                          value={leadsDetails.ref_email ? leadsDetails.ref_email : 'Undefined'}
                          sx={{width: '100%'}}
                          className="csttextfield"
                          InputProps={{
                            endAdornment: <InputAdornment position="end"><NotInterestedRoundedIcon sx={{color: '#303030', fontSize: '14px'}} /></InputAdornment>,
                          }}
                        />
                        {/* <InputEmail
                          InputLabel="Reference Email Id"
                          InputType="email"
                          InputName="ref_email"
                          Required={true}
                          InputRef={refranceemail}
                          InputId="reflemail"
                        />    */}
                      </Grid>
                    </React.Fragment>
                  : source_type === "Broker" ?
                    <React.Fragment>
                      <Grid item={true} mt={2} xs={12} > 
                        {/* <InputAutocomplete 
                          inputLable="Broker"
                          sourcetype="Broker"
                          brokerServices={["Vinayak Jaiswal", "Nitin Parikh", "Rajan Saraf"]}
                          inputname="broker_name"
                          inputRequired={true}
                        /> */}
                        <TextField
                          disabled
                          id="outlined-disabled"
                          label="Broker"
                          // name="broker_name" 
                          value={'Undefined'}
                          sx={{width: '100%'}}
                          className="csttextfield"
                          InputProps={{
                            endAdornment: <InputAdornment position="end"><NotInterestedRoundedIcon sx={{color: '#303030', fontSize: '14px'}} /></InputAdornment>,
                          }}
                        />
                      </Grid>
                    </React.Fragment>
                  : null
                  }
                <Grid
                  mt={2} 
                  style={{
                    background: "#fff",
                  }}
                  container
                >  
                  <Grid item={true} xs={12}> 
                    {/* {numError === false ? */}
                      <InputsLabel InputsLabel="Alternative Mobile No" />
                    {/* :  */}
                      {/* <InputErrorMessage errormsg="invalid mobile no." /> */}
                    {/* } */}
                  </Grid> 
                  <Grid item={true} pt={1} xs={12}>
                    <IntlDropdown 
                      Inputname={["sccode", "smobile"]} 
                      // InputRequired={true} 
                      InputRef={[sccode, snumber]} 
                      InputId={['sleadccode', 'sleadnumber']}
                      defautValue={[
                        leadsDetails.s_ccode ? leadsDetails.s_ccode : null,
                        leadsDetails.s_mob ? leadsDetails.s_mob : null
                      ]}
                    />
                    {/* <TextField
                      disabled
                      id="outlined-disabled"
                      label="Lead Number"
                      // name="lname" 
                      value={leadsDetails.p_mob ? leadsDetails.p_ccode+' '+leadsDetails.p_mob : 'Undefined'}
                      sx={{width: '100%'}}
                      className="csttextfield"
                      InputProps={{
                        endAdornment: <InputAdornment position="end"><NotInterestedRoundedIcon sx={{color: '#303030', fontSize: '14px'}} /></InputAdornment>,
                      }}
                    /> */}
                  </Grid> 
                </Grid>
                <Grid
                  mt={2} 
                  pb={2}
                  style={{
                    background: "#fff",
                  }}
                  container
                > 
                  <Grid item={true} xs={12}> 
                    {/* {emailError === false ? */}
                      <InputsLabel InputsLabel="Alternative Email Id" />
                    {/* :  */}
                    {/* <InputErrorMessage errormsg="email id is required." /> */}
                    {/* } */}
                  </Grid> 
                  <Grid item={true} pt={1} xs={12}>
                    <InputEmail
                      InputLabel="alphabets, numbers, @, -, . only"
                      InputType="email"
                      InputName="semail"
                      // Required={emailError === false ? false : true}
                      // InputRef={refemail}
                      InputId="sleademail"
                      defautValue={leadsDetails.s_email ? leadsDetails.s_email : null}
                    />
                    {/* <TextField
                      disabled
                      id="outlined-disabled"
                      label="Alternative Email Id"
                      // name="lemail" 
                      value={leadsDetails.p_email ? leadsDetails.p_email : 'Undefined'}
                      sx={{width: '100%'}}
                      className="csttextfield"
                      InputProps={{
                        endAdornment: <InputAdornment position="end"><NotInterestedRoundedIcon sx={{color: '#303030', fontSize: '14px'}} /></InputAdornment>,
                      }}
                    /> */}
                  </Grid>
                </Grid>
                </Grid>
                <Divider variant="inset" sx={{ ml: 0, width: '100%'}} />
                <Grid
                  p={2}
                  style={{
                    background: "#fff",
                  }}
                  container
                >
                  <Grid item={true} xs={12}>
                    <InputsLabel InputsLabel="Other Details" /> 
                  </Grid> 
                  <Grid item={true} pt={1} xs={12}>
                    <InputMessage 
                      inputName="other_details"
                      defautValue={leadsDetails.other_details ? leadsDetails.other_details : null} 
                    />
                  </Grid>
                </Grid> 
                <Divider variant="inset" sx={{ ml: 0, width: '100%'}} />
              </React.Fragment>
            : null}
          </Grid>
          <Divider variant="inset" sx={{ ml: 0, width: '100%'}} />
          <Grid 
            style={{
              background: "#fff",
            }}
            container
          >
            <Grid item={true} xs={12}>
              <Button
                sx={{ p: 1.6 }}
                className="lead-moredetails-btn"
                onClick={() => setclientreqShow(!clientreqshow)}
              >
                <div className="add-leadmore">Client Requirements</div>
                {clientreqshow ? (
                  <KeyboardArrowUpIcon className="add-leadmore-icon" />
                ) : (
                  <KeyboardArrowDownIcon className="add-leadmore-icon" />
                )}
              </Button>  
            </Grid>
             
            {clientreqshow ?
              <React.Fragment>
                <Divider variant="hr" sx={{width: '100%'}} />
                <Grid 
                  sx={{px: 1.6, pt: 1.5}}
                  container
                > 
                  <Grid item={true} pt={1} xs={12} >
                    <InputAutocomplete 
                      inputLable="Project type"
                      sourcetype="Project Type"
                      services={["Residential", "Commercial"]}
                      inputname="ptype"
                      inputRequired={false}
                      defautValue={leadsDetails.ptype ? leadsDetails.ptype : null} 
                    />
                  </Grid> 
                  <Grid item={true} mt={2} xs={12} >
                    <InputAutocomplete 
                      inputLable="Project Category"
                      sourcetype="Project Category"
                      services={["Apartment", "Flat"]}
                      inputname="pcategory"
                      inputRequired={false}
                      defautValue={leadsDetails.pcategory ? leadsDetails.pcategory : null} 
                    />
                  </Grid>  
                  <Grid item={true} mt={2} xs={12} > 
                    <InputMultiAutocomplete
                      inputLable="Select Configuration"
                      sourcetype="Select Configuration"
                      options={[
                        { title: '1 BHK'}, 
                        { title: '2 BHK'},
                        { title: '3 BHK'},
                        { title: '4 BHK'},
                        { title: '5 BHK'}
                      ]}
                      inputname="pconfiguration"
                      defautValue={leadsDetails.pconfiguration ? leadsDetails.pconfiguration : null} 
                    />
                  </Grid>
                  
                  <Grid item={true} mt={1.5} xs={12}>
                    <InputsLabel InputsLabel="Carpet area" />
                  </Grid>
                  <Grid container pt={2}>
                    <Grid item={true} xs={12}>
                      <CarpetAreaInput
                        unitsname="units_type"
                        minareaname="min_area"
                        maxareaname="max_area"
                        unitlist={["Sq Feet", "Sq Meter", "Sq Yards", "Acres", "Marla", "Cents", "Biggha", "Kottah", "Canals", "Grounds", "Ares", "Guntha", "Biswa", "Hectars", "Roods", "Chataks", "Perch"]}
                        defautUnitValue={leadsDetails.area_unit ? leadsDetails.area_unit : null}
                        defaultMinArea={leadsDetails.min_area ? leadsDetails.min_area : null}
                        defaultMaxArea={leadsDetails.max_area ? leadsDetails.max_area : null}
                      /> 
                    </Grid> 
                  </Grid> 

                  <Grid item={true} mt={1.5} xs={12}>
                    <InputsLabel InputsLabel="Pricing" /> 
                  </Grid> 
                  <Grid container pt={2}> 
                    <Grid item={true} xs={12}>
                      <PricingInput
                        currencyname="currency_type"
                        minpricename="min_price"
                        maxpricename="max_price"
                        defaultCurrency={leadsDetails.price_unit ? leadsDetails.price_unit : null}
                        defaultMinPrice={leadsDetails.min_price ? leadsDetails.min_price : null}
                        defaultMaxPrice={leadsDetails.max_price ? leadsDetails.max_price : null}
                      /> 
                    </Grid> 
                  </Grid>   
                  <Grid item={true} xs={12} ><br /></Grid>
                </Grid>
              </React.Fragment> 
            : null }   
          </Grid>
          <Divider variant="inset" sx={{ ml: 0, width: '100%'}} />
          <Grid 
            style={{
              background: "#fff",
            }}
            container
          >
            <Grid item={true} xs={12}>
              <Button
                sx={{ p: 1.6 }}
                className="lead-moredetails-btn"
                onClick={() => setprjdetailsShow(!prjdetailsshow)}
              >
                <div className="add-leadmore">Project Details</div>
                {prjdetailsshow ? (
                  <KeyboardArrowUpIcon className="add-leadmore-icon" />
                ) : (
                  <KeyboardArrowDownIcon className="add-leadmore-icon" />
                )}
              </Button> 
            </Grid>
            {prjdetailsshow ? 
              <React.Fragment>
                <Divider variant="hr" sx={{width: '100%'}} />
                <Grid 
                  sx={{px: 1.6, pt: 1.5}}
                  container
                > 
                  <Grid item={true} pt={1} xs={12} > 
                    <InputMultiAutocomplete
                      inputLable="Select Project"
                      sourcetype="Select Project"
                      options={[
                        { title: 'Lodha Woods'}, 
                        { title: 'E Heights'},
                        { title: 'Piramals'},
                        { title: 'Kohinoor'}
                      ]}
                      inputname="pname"
                      defautValue={leadsDetails.pname ? leadsDetails.pname : null}
                    />
                  </Grid>  
                  <Grid item={true} pt={2} xs={12} >
                    <InputCountryStateCity 
                      inputNames={["country", "state", "city", "locality", "sub_locality"]}
                      defautCountry={leadsDetails.country ? leadsDetails.country : null}
                      defautState={leadsDetails.state ? leadsDetails.state : null}
                      defautCity={leadsDetails.city ? leadsDetails.city : null}
                      defautLocality={leadsDetails.locality ? leadsDetails.locality : null}
                      // defautSubLocality={leadsDetails.sub_locality ? leadsDetails.sub_locality : null}
                    />
                  </Grid>    
                  <Grid item={true} xs={12} ><br /></Grid>
                </Grid>
              </React.Fragment>
            : null }

            <input type="hidden" name="status" value={leadsDetails.status}/>
            <input type="hidden" name="followup_dt" value={leadsDetails.followup_dt}/>
          </Grid>
          <Divider variant="div" sx={{ mb: 4, width: '100%'}} />
          <Button
            variant="contained"
            startIcon={<SaveIcon />}
            className="add-submit-button"
            type="submit"
          >
            Save Lead
          </Button>
        </form>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </div>
    </Fade>
  );
};

export default EditLead;