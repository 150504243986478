import React from 'react'
import styled from 'styled-components'
import { useTable, usePagination, useSortBy } from 'react-table'
import { Button, Tooltip } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { getLeads, unSetLead } from './ApiCalls';
import AddIcon from "@mui/icons-material/Add";
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Cookies from 'js-cookie';
import '../reacttable.css';
import { useQueryClient, useQuery } from 'react-query';
import dayjs from 'dayjs';
import { isSameDay, subDays } from 'date-fns';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import ReactWhatsapp from 'react-whatsapp';

function Table({
  columns,
  data,
  fetchData,
  loading,
  pageCount: controlledPageCount,
}) {
  const today_date = dayjs().format('YYYY-MM-DD') 
  const yesterday =  dayjs(subDays(new Date(), 1)).format('YYYY-MM-DD')
  const [controlledPageIndex, setControlledPage] = React.useState(0)
  const [open, setOpen] = React.useState(false);
  const [leadId, setLeadId] = React.useState([]);

  // check all 
  const [isCheckAll, setIsCheckAll] = React.useState(false);
  const [list, setList] = React.useState([]);

  //  
  const lastlocation = useParams(); 

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: localStorage.getItem('tablePageIndex') !== 'null' ? Number(localStorage.getItem('tablePageIndex')) : controlledPageIndex, pageSize: 30}, 
      manualPagination: true,
      autoResetPage: false,
      pageCount: controlledPageCount
    },
    useSortBy,
    usePagination
  )

  React.useEffect(() => {
    fetchData({ pageIndex, pageSize })
  }, [fetchData, pageIndex, pageSize])
  
  React.useEffect(() => {
    setList(page);  
  }, [page, list, leadId]);

  const handleClickOpen = (lid) => {
    setOpen(true);
    setLeadId(lid);
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  const handleRemove = (valueToRemove) => {
    const index = leadId.indexOf(valueToRemove);
    if (index !== -1) {
      const newArray = leadId.slice(0, index).concat(leadId.slice(index + 1));
      setLeadId(newArray);
    }
  };

  const deleteLead = async(lids) => {
    unSetLead(lids)
    .then((response) => {
      if(response.data === 'lead deleted'){
        handleClose();
        alert("Leads Deleted Successfully");
      }
      console.log(response)
    })
    .catch((err) => {
      console.log(err);
    });
  };
  
  const next = () =>{
    nextPage()
    setControlledPage(pageIndex)
    localStorage.setItem('tablePageIndex', Math.round(pageIndex+1));
  }

  const prev = () =>{
    previousPage()
    setControlledPage(pageIndex)
    localStorage.setItem('tablePageIndex', Math.round(pageIndex-1));
  }

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setLeadId(list.map(id => id.original.l_id))
    if (isCheckAll) {
      setLeadId([]);
    }
  };

  const handleSelect = (e) => {
    const { value, checked } = e.target; 
    setLeadId([...leadId, parseInt(value)])
    if (!checked) {
      setLeadId(leadId.filter(item => item !== parseInt(value)));
    } 
  };

  return (
    <>
    {Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin' || Cookies.get('role') === 'Team Leader' || Cookies.get('role') === 'Sales Manager' || Cookies.get('role') === 'Branch Admin' ? 
      <Breadcrumb 
        PageName="Non Assign Leads" 
        BackButton={[Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin' ? true : false, 'Add', '/add-lead/nonassignleads', <AddIcon />]} 
        AssignButton={[leadId.length !== 0 ? true : false, 'Assign Leads', `/assign-leads-to/${leadId}/mypage=${pageIndex}/direct/nonassignleads`, <AddIcon />]}
      /> 
      :
      <Breadcrumb 
        PageName="Non Assign Leads" 
        BackButton={[Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin' ? true : false, 'Add', '/add-lead/nonassignleads', <AddIcon />]}  
      /> 
    }
    {leadId.length !== 0 && (Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin') ? <Button
            variant="outlined"
            sx={{ mx: 1, justifyContent: 'left', position: 'absolute',
            right: '1rem',
            top: '5.5rem',
            zIndex: 1000 }}
            startIcon={<DeleteIcon />}
            onClick={() => handleClickOpen(leadId)}
    >Delete</Button>  : ''}

    <div className='table'>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              <th ><input 
                  type="checkbox" 
                  id='selectAll'
                  name='selectAll'
                  onChange={handleSelectAll}
                  checked={isCheckAll}
                /></th>
              <th >Action</th>
              <th >Sr. No</th>
              {headerGroup.headers.map((column, index) => (
                <th key={i} {...column.getHeaderProps(column.getSortByToggleProps())} style={{position: 'relative'}}>
                  {column.render('Header')}
                  <span style={{color: '#e9e9e9', position: 'absolute', top: '1rem', right: '0.3rem'}}>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                      <FilterAltIcon />
                      ) : (
                      <FilterAltOffIcon />
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => { 
            prepareRow(row)
            return (
              <tr key={i} {...row.getRowProps()} style={row.original.l_id === 9 ? {background: '#000000'} : {}}>
                <td >
                <input 
                    type="checkbox" 
                    name={'checkbox'+i} 
                    id={'checkbox'+i} 
                    value={row.original.l_id} 
                    // onChange={(e)=>{e.target.checked ? setLeadId(current => [...current, row.original.l_id]) : handleRemove(row.original.l_id)}}  
                    onChange={handleSelect}
                    checked={leadId.includes(row.original.l_id)}
                  />
                </td> 
                <td >  
                  <Tooltip
                    title="Quick Edit"
                    component={Link}
                    to={`/quick-edit/${row.original.l_id}/mypage=${pageIndex}/nonassignleads`} 
                    sx={{py: 0, px: '5px', borderRadius: '5px'}}
                  >
                    <IconButton ><AutoFixHighIcon sx={{ fontSize: "18px" }} /></IconButton>
                  </Tooltip>
                  <Tooltip
                    title="Edit"
                    component={Link}
                    to={`/edit-lead/${row.original.l_id}/mypage=${pageIndex}/nonassignleads`}
                    sx={{py: 0, px: '5px', borderRadius: '5px'}}
                  >
                    <IconButton><EditIcon sx={{ fontSize: "18px" }} /></IconButton>
                  </Tooltip>
                  <Tooltip
                    title="View"
                    component={Link}
                    to={`/view-lead/${row.original.l_id}/mypage=${pageIndex}/nonassignleads`}
                    sx={{py: 0, px: '5px', borderRadius: '5px'}}
                  >
                    <IconButton><VisibilityIcon sx={{ fontSize: "18px" }} /></IconButton>
                  </Tooltip>
                  {/* {Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin'  ?
                    <Tooltip
                      title="Delete"
                      component="label"
                      onClick={() => handleClickOpen(row.original.l_id)} 
                      sx={{py: 0, px: '5px', borderRadius: '5px'}}
                    ><IconButton><DeleteIcon sx={{ fontSize: "18px" }} /></IconButton>
                    </Tooltip>
                  : ''} */}

                  <hr style={{border: '#ffffff'}} />
                  <Tooltip
                    title="Call" 
                    sx={{py: 0, px: '5px', borderRadius: '5px'}} 
                    target='_blank'
                    href={'tel:+'+row.original.p_ccode+row.original.p_mob}
                  >
                    <IconButton><LocalPhoneIcon sx={{ fontSize: "18px" }} /></IconButton>
                  </Tooltip>
                  
                  <ReactWhatsapp 
                    number={'+'+row.original.p_ccode+row.original.p_mob} 
                    message="Hello,"  
                    style={{outline: 'none', border: 'none', background: 'transparent', cursor: 'pointer', color: 'rgba(0, 0, 0, 0.54)', paddingLeft: '5px', paddingRight: '5px'}}
                  >
                    <Tooltip
                      title="Whatsapp" 
                      sx={{py: 0, px: '5px', borderRadius: '5px'}}
                    >
                      <WhatsAppIcon sx={{ fontSize: "18px", position: 'relative', top: '5px' }} />
                    </Tooltip>
                  </ReactWhatsapp> 
                  
                  <Tooltip
                    title="Email" 
                    sx={{py: 0, px: '5px', borderRadius: '5px'}}
                    target='_blank'
                    href={'mailto:'+row.original.p_email}
                  >
                    <IconButton><MarkunreadIcon sx={{ fontSize: "18px" }} /></IconButton>
                  </Tooltip> 

                </td>
                <td>{i}</td>

                {row.cells.map((cell, i) => (
                  <>
            
                    {/* {cell.column.Header === 'Status' && row.cells[6].value ? 
                      <td key={i} style={{background: '#000000'}} {...cell.getCellProps()}>{cell.render('Cell')}</td> : cell.column.Header === 'Follow Up Date' && row.original.followup === 'Yes' ? <td>{dayjs(row.cells[1].value).format('YYYY-MM-DD') === today_date ? 'Today' : ''}</td> : <td key={i} {...cell.getCellProps()}>{cell.render('Cell')}</td>} */}

                      {cell.column.Header === 'Lead Status' && row.cells[8].value ?               
                        <td key={i} {...cell.getCellProps()} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <Tooltip
                            title={cell.render('Cell')}
                            sx={{py: 0}}
                          > 
                            <span style={{backgroundColor: row.original.color, padding: '5px 8px', color: '#ffffff'}}>{cell.render('Cell')}</span> 
                          </Tooltip>
                        </td> 
                        // : 
                        // cell.column.Header === 'Follow Up Date' && row.original.followup === 'Yes'
                        // ? 
                        // <td>{dayjs(row.cells[1].value).format('YYYY-MM-DD') === today_date ? 'Today' : ''}</td> 
                      : 
                      <td key={i} {...cell.getCellProps()}>
                      {cell.column.Header === 'Lead Date' ? 
                        dayjs(row.cells[2].value).format('YYYY-MM-DD') === today_date ? 
                          <Tooltip
                            title={'Today At '+dayjs(row.cells[2].value).format('hh:mm A')}
                            sx={{py: 0}}
                          > 
                            <span>{'Today At '+dayjs(row.cells[2].value).format('hh:mm A')}</span>
                          </Tooltip>
                        : dayjs(row.cells[2].value).format('YYYY-MM-DD') === yesterday ? 
                          <Tooltip
                            title={'Yesterday At '+dayjs(row.cells[2].value).format('hh:mm A')}
                            sx={{py: 0}}
                          > 
                            <span>{'Yesterday At '+dayjs(row.cells[2].value).format('hh:mm A')}</span>
                          </Tooltip>
                        : 
                          <Tooltip
                            title={dayjs(row.cells[2].value).format('MMM, DD YYYY - hh:mm A')}
                            sx={{py: 0}}
                          >   
                            <span>{dayjs(row.cells[2].value).format('MMM, DD YYYY - hh:mm A')}</span> 
                          </Tooltip>
                        : cell.column.Header === 'Follow Up Date' ? 
                        dayjs(row.cells[8].value).format('YYYY-MM-DD') === today_date ? 
                        <Tooltip
                          title={'Today At '+dayjs(row.cells[8].value).format('hh:mm A')}
                          sx={{py: 0}}
                        > 
                          <span>{'Today At '+dayjs(row.cells[8].value).format('hh:mm A')}</span>
                        </Tooltip>
                      :  dayjs(row.cells[8].value).format('YYYY-MM-DD') ===     yesterday ? 
                        <Tooltip
                          title={'Yesterday At '+dayjs(row.cells[8].value).format('hh:mm A')}
                          sx={{py: 0}}
                        > 
                          <span>{'Yesterday At '+dayjs(row.cells[8].value).format('hh:mm A')}</span>
                        </Tooltip>
                      : 
                        <Tooltip
                          title={dayjs(row.cells[8].value).format('MMM, DD YYYY - hh:mm A')}
                          sx={{py: 0}}
                        >   
                          <span>{dayjs(row.cells[8].value).format('MMM, DD YYYY - hh:mm A')}</span> 
                        </Tooltip>
                      :   
                        <Tooltip
                          title={cell.render('Cell')}
                          sx={{py: 0}}
                        >
                          <span>{cell.render('Cell')}</span>
                        </Tooltip>
                      }
                    </td>
                    }
                  </>
                ))}
              </tr> 
            )
          })}

          <tr style={{backgroundColor:'#ffffff'}}>
            {loading ? (
              <td style={{border: 'none'}}>Loading...</td>
            ) : (
              <td style={{border: 'none'}}>
                Showing {page.length} of {controlledPageCount}{' '}
                results
              </td>
            )}

            <td className="pagination" style={{border: 'none'}}>
              <Button sx={{p: 0, minWidth: '2rem'}} onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {'<<'}
              </Button>{' '}
              <Button sx={{p: 0, minWidth: '2rem'}} onClick={() => prev()} disabled={!canPreviousPage}>
                {'<'}
              </Button>{' '}

              <span>
                &nbsp;Page{' '}
                <strong>
                  {Math.round(pageIndex + 1)} of {Math.round(controlledPageCount/pageSize)}
                </strong>{' '}&nbsp;
              </span>

              <Button sx={{p: 0, minWidth: '2rem'}} onClick={() => next()} disabled={!canNextPage}>
                {'>'}
              </Button>{' '}
              <Button sx={{p: 0, minWidth: '2rem'}} onClick={() => gotoPage(controlledPageCount/pageSize - 1)} disabled={!canNextPage}>
                {'>>'}
              </Button> 

              {/* <span>
                | Go to page:{' '}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                    gotoPage(page)
                  }}
                  style={{ width: '100px' }}
                />
              </span>{' '} */}
              {/* <select
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value))
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize, i) => (
                  <option key={i} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select> */}
              <select
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value))
                }}
                style={{fontSize: '13px', border: 'none'}}
              >
                {[30, 50, 100, 200].map((pageSize, i) => (
                  <option key={i} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </td>
          </tr>
        </tbody>
       </table>
      </div>

      {/* delete confirm */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete?"}
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={() => deleteLead(leadId)} autoFocus>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

function NonAssignLeads() {

  const columns = React.useMemo(
    () => Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin' || Cookies.get('role') === 'Branch Admin' ?
      [
        {
          Header: 'Lead Id',
          accessor: 'l_id',
        },
        {
          Header: 'Assigned Lead Id',
          accessor: '',
        },
        {
          Header: 'Lead Date',
          accessor: 'create_dt',
        },
        {
          Header: 'Lead Name',
          accessor: 'lname',
        },
        {
          Header: 'Mobile Number',
          accessor: 'mobile',
        },
        {
          Header: 'Email Id',
          accessor: 'p_email',
        },
        {
          Header: 'Project Name',
          accessor: 'pname',
        },
        {
          Header: 'Lead Status',
          accessor: 'status',
        },
        {
          Header: 'Follow Up Date',
          accessor: 'followup_dt',
        }, 
        {
          Header: 'Source',
          accessor: 'source',
        },
        {
          Header: 'Comments',
          accessor: 'comments',
        },
        {
          Header: 'City',
          accessor: 'city',
        },
        {
          Header: 'Locality',
          accessor: 'locality',
        },
        {
          Header: 'Sub Locality',
          accessor: 'sub_locality',
        },  
      ]
    :
      [
        {
          Header: 'Lead Id',
          accessor: 'assignlead_id',
        },
        {
          Header: 'Assigned Lead Id',
          accessor: '',
        },
        {
          Header: 'Lead Date',
          accessor: 'create_dt',
        },
        {
          Header: 'Lead Name',
          accessor: 'lname',
        },
        {
          Header: 'Mobile Number',
          accessor: 'mobile',
        },
        {
          Header: 'Email Id',
          accessor: 'p_email',
        },
        {
          Header: 'Project Name',
          accessor: 'pname',
        },
        {
          Header: 'Lead Status',
          accessor: 'status',
        },
        {
          Header: 'Follow Up Date',
          accessor: 'followup_dt',
        }, 
        {
          Header: 'Source',
          accessor: 'source',
        },
        {
          Header: 'Comments',
          accessor: 'comments',
        },
        {
          Header: 'City',
          accessor: 'city',
        },
        {
          Header: 'Locality',
          accessor: 'locality',
        },
        {
          Header: 'Sub Locality',
          accessor: 'sub_locality',
        },  
      ],
    []
  )

  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const fetchIdRef = React.useRef(0)
  const queryClient = useQueryClient();
  const totalleadcount = queryClient.getQueryData('nonassigncount');

  const fetchData = React.useCallback(({ pageSize, pageIndex, data })  => {

    const fetchId = ++fetchIdRef.current
    // setLoading(true)
    // setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex
        const endRow = startRow + pageSize
        getLeads(pageSize, Math.round(startRow))
        .then((response) => {
          setData(response.data !== 'No Data Found' ? response.data : [])
        })
        .catch((err) => {
          console.log(err);
        });      
        setLoading(false)
      }
    // }, 1000)
  }, [])

  const memoizedData = React.useMemo(() => data, [data]);

  return (
      <Table
        columns={columns}
        data={memoizedData}
        fetchData={fetchData}
        loading={loading}
        pageCount={Number(totalleadcount)}
      />
  )
}

export default NonAssignLeads
