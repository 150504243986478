import React, { useState, useEffect } from "react";
import { Link , useNavigate } from "react-router-dom";
import { PageHeader } from "../../Components/PageHeader/PageHeader";
import AccountBar from "../../Components/AccountBar/AccountBar";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Fade from '@mui/material/Fade';
import userImg from "./user.png";
import "./Account.css";
import { getProfile, logout, setToken } from "./ApiCalls"; 
import Cookies from 'js-cookie';

const Account = ({token}) => {
 
  const[accountDetails, setAccountDetails] = useState("");
  //const token = localStorage.getItem('token');
  const navigate = useNavigate();
  
  useEffect(() => {
    
    getProfile()
    .then((response) => {
      console.log(response);
      setAccountDetails(response.data[0])
    })
    .catch((err) => {
      console.log(err);
    });
  }, []);

  const logout_User = () => {
    logout(token)
      .then((response) => { 
        Cookies.remove('user');
        Cookies.remove('role');
        navigate('/login');
      })
    .catch((err) => {
      console.log(err);
    });
  };

  const verify_token = () => {
    setToken(token)
    .then((response) => {
      console.log(response)
    })
    .catch((err) => {
      console.log(err);
    });
  };

  return (
    <>
      <PageHeader pagename="Account" />
      <Divider variant="span" sx={{ mt: 1 }} />
      <Fade in timeout={800}>
        <Card className="user-account-card" component={Link} to="/account">
          <Avatar
            sx={{ width: 80, height: 80, margin: "auto 0" }}
            src={userImg}
            alt="User"
          />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <CardContent sx={{ flex: "1 0 auto" }}>
              <Typography component="div" variant="h5" className="custom-title">
                {accountDetails.fname ? accountDetails.fname + " " + accountDetails.mname + " " + accountDetails.lname : '...'}
              </Typography> 
              <Typography
                component="div"
                variant="h5"
                className="custom-subtitle"
                sx={{ mt: 0.3 }}
              >
                {accountDetails.r_email ? accountDetails.r_email : '...'}
              </Typography>
              <Typography
                component="div"
                variant="h5"
                className="custom-subtitle"
                sx={{ mt: 0.3 }}
              >
                {accountDetails.designation ? accountDetails.designation : '...'}
              </Typography>
            </CardContent>
          </Box>
          {/* <ChevronRightIcon className="right-icon" /> */}
        </Card>
      </Fade>

      <Divider variant="span" sx={{ mb: 1.5 }} />
      
      <AccountBar account_title="Device Token: " account_subtitle={accountDetails.device_token ? "Verified" : <span onClick={verify_token}>Verify Now</span>} />

      <Divider variant="span" sx={{ mb: 1.5 }} />
      <AccountBar account_title="Expires on: " account_subtitle="31/03/2023" />
      <Divider variant="span" sx={{ mt: 1.5 }} />

      {/* <Typography component="div" variant="h5" className="account-head">
        Account Preferences
      </Typography>

      <Divider variant="span" sx={{ mt: 1.5 }} />
      <AccountBar account_title="Team View" account_subtitle="All Clients" />
      <Divider variant="span" sx={{ mt: 0 }} />
      <AccountBar account_title="Setting" />
      <Divider variant="span" sx={{ mt: 0 }} />
      <AccountBar account_title="Integration" />
      <Divider variant="span" sx={{ mb: 1.5 }} />

      <Typography component="div" variant="h5" className="account-head">
        Customer Support
      </Typography>

      <Divider variant="span" sx={{ mt: 1.5 }} />
      <AccountBar account_title="User Guide" />
      <Divider variant="span" sx={{ mb: 0 }} />
      <AccountBar account_title="Chat With Us" />
      <Divider variant="span" sx={{ mb: 1.5 }} />

      <Typography component="div" variant="h5" className="account-head">
        Invite Friends
      </Typography>

      <Divider variant="span" sx={{ mt: 1.5 }} />
      <AccountBar account_title="Get Free Credits" />
      <Divider variant="span" sx={{ mb: 5 }} /> */}

      <Fade in timeout={800}>
        <ListItem button className="logout-bar" onClick={logout_User}>
          <h3>Log Out</h3>
        </ListItem>
      </Fade>
      <Divider variant="span" sx={{ mb: 2 }} />
      <Typography
        component="div"
        sx={{ mb: 8 }}
        variant="h5"
        className="version-head"
      >
        Version: 1.0
      </Typography>
    </>
  );
};

export default Account;