import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";

// Replace this firebaseConfig object with the congurations for the project you created on your firebase console.
const firebaseConfig = {
  apiKey: "AIzaSyBo0U1OMSkx3mdjs0KGaTGcafqOgDUY1Sg",
  authDomain: "push-notification-6787b.firebaseapp.com",
  projectId: "push-notification-6787b",
  storageBucket: "push-notification-6787b.appspot.com",
  messagingSenderId: "181960348200",
  appId: "1:181960348200:web:77424b9db7e691df44db1d",
};
const app = initializeApp(firebaseConfig);
// export const messaging = getMessaging();
export const messaging = getMessaging(app);

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });
