import * as React from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { NavLink, Outlet, Navigate, useNavigate} from "react-router-dom";
import { styled, useTheme, alpha } from "@mui/material/styles";
// import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
// import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
// import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import DashboardIcon from "@mui/icons-material/Dashboard";
// import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
// import ApartmentIcon from "@mui/icons-material/Apartment";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import NotificationsIcon from "@mui/icons-material/Notifications";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import CloseIcon from '@mui/icons-material/Close';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image"; 
import Logo from "./man.png";
import Icon from "./icon.png";
import User from "./man.png";
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// import TextField from '@mui/material/TextField';
// import TuneIcon from '@mui/icons-material/Tune';
import FingerprintIcon from "@mui/icons-material/Fingerprint";
// import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Webcam from "react-webcam";
// import HomeWorkIcon from "@mui/icons-material/HomeWork";
// import ChaletIcon from "@mui/icons-material/Chalet";
// import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
// import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
// import AssessmentIcon from "@mui/icons-material/Assessment";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import PersonIcon from "@mui/icons-material/Person";
// import SettingsInputCompositeIcon from "@mui/icons-material/SettingsInputComposite";
import "./LeftSideNav.css";
import { logout } from '../../../Pages/Account/ApiCalls';
import Fade from '@mui/material/Fade';
import Cookies from 'js-cookie';
import { useQuery } from "react-query";
import { Alert } from "@mui/material";
import { getLeadsCount, getNonAssignLeadsCount, getAssignLeadsCount, getMissedLeadsCount, getPresentLeadsCount, getStatusCount, getNewLeadCount, getUndefinedLeadCount, getUpcomingLeadCount, getBellLeads, UpdateUserAttendance, getAttendanceStatus} from "./ApiCalls";
import Chip from '@mui/material/Chip';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// left navigation
const drawerWidth = 290;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// searchbar
// const Search = styled("div")(({ theme }) => ({
//   position: "relative",
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   "&:hover": {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginRight: theme.spacing(2),
//   marginLeft: 0,
//   width: "100%",
//   [theme.breakpoints.up("sm")]: {
//     marginLeft: theme.spacing(3),
//     width: "auto",
//   },
// }));
// const SearchIconWrapper = styled("div")(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: "100%",
//   position: "absolute",
//   pointerEvents: "none",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// }));
// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: "inherit",
//   "& .MuiInputBase-input": {
//     padding: theme.spacing(1, 1, 1, 0),
//     // vertical padding + font size from searchIcon
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create("width"),
//     width: "100%",
//     [theme.breakpoints.up("md")]: {
//       width: "30ch",
//     },
//   },
// }));

export default function LeftSideNav({token}) {
  // left navigation
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  
  const logout_User = () => {
    logout(token)
      .then((response) => {
        Cookies.remove('user');
        Cookies.remove('role');
        navigate('/login');
      })
    .catch((err) => {
      console.log(err);
    });
  };

  // user profile
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  // const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id="menuId"
      open={isMenuOpen}
      onClose={handleMenuClose}
      onClick={handleMenuClose}
      MenuListProps={{ onMouseLeave: handleMenuClose }}
      className="dropdown"
      PaperProps={{
        elevation: 0,
        sx: {
          width: "180px",
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: -0.8,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {/* <MenuItem onClick={handleMenuClose}>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        Profile
      </MenuItem> */}
      {/* <Divider /> */}
      <MenuItem onClick={logout_User}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  );

  // notifications
  const [notifyEl, setNotifyEl] = React.useState(null);
  const isNotifyMenuOpen = Boolean(notifyEl);
  const notifyProfileMenuOpen = (event) => {
    setNotifyEl(event.currentTarget);
  };
  const notifyMenuClose = () => {
    setNotifyEl(null);
  };
  const notifyMenuId = "primary-notify-account-menu";
  
  // camera modal
  const webcamRef = React.useRef(null);
  const [url, setUrl] = React.useState(null);

  const capturePhoto = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setUrl(imageSrc);
  }, [webcamRef]);

  const [openModal, setOpenModal] = React.useState(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 330,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: "10px"
  };

  const videoConstraints = {
    width: 300,
    facingMode: "user"
  };

  const onUserMedia = (e) => {
    console.log(e);
  };

  const UserAttendance = (event) => {
    event.preventDefault();
    UpdateUserAttendance(url, attendancestatus.data.data)
    .then((response) => {
      if (response.status === 200) {
        attendancestatus.refetch();
        setOpenModal(false);
        setUrl(null);
        notify(attendancestatus.data.data === "In Time" ? "Out Time Done Successfully." : "In Time Done Successfully.");
      }
    })
    .catch((err) => {
      console.log(err)
    });
  }

  // filter date
  const [ufdate, setfUdate] = React.useState(dayjs("2023-01-01T21:11:54"));
  const ufdateChange = (newfUalue) => {
    setfUdate(newfUalue);
  };
  const [utdate, settUdate] = React.useState(dayjs("2023-01-01T21:11:54"));
  const utdateChange = (newtUalue) => {
    settUdate(newtUalue);
  };

  // open submenu
  const [ShowLead, setShowLead] = React.useState(false);
  const [ShowUser, setShowUser] = React.useState(false);
  const [ShowDynamicField, setDynamicField] = React.useState(false);

  React.useEffect(() => {
    handleDrawerOpen();
    setTimeout(() => {
      setShowLead(!ShowLead);
      setShowUser(!ShowUser);
      setDynamicField(!ShowDynamicField);
    }, 1200);
  }, []);

  const handleClickLead = () => {
    setShowLead(!ShowLead);
  };
  const handleClickUser = () => {
    setShowUser(!ShowUser);
  };
  const handleClickDynamicField = () => {
    setDynamicField(!ShowDynamicField);
  };

  const totalleadcount = useQuery("leadscount", () => {
    return getLeadsCount()
  },{})

  const nonassigncount = useQuery("nonassigncount", () => {
    return getNonAssignLeadsCount()
  },{})

  const assigncount = useQuery("assigncount", () => {
    return getAssignLeadsCount()
  },{})

  const missedcount = useQuery("missedcount", () => {
    return getMissedLeadsCount()
  },{})

  const presentcount = useQuery("presentcount", () => {
    return getPresentLeadsCount()
  },{})

  const statuscount = useQuery("statuscount", () => {
    return getStatusCount()
  },{})

  const newcount = useQuery("newleadcount", () => {
    return getNewLeadCount()
  },{})

  const undefinedcount = useQuery("undefinedleadcount", () => {
    return getUndefinedLeadCount()
  },{})

  const upcomingcount = useQuery("upcomingcount", () => {
    return getUpcomingLeadCount()
  },{})

  const bellleads = useQuery("bellleads", () => {
    return getBellLeads();
  },{});
 
  const attendancestatus = useQuery("attendancestatus", () => {
    return getAttendanceStatus()
  }, {});

  if(totalleadcount.isLoading || nonassigncount.isLoading || assigncount.isLoading || missedcount.isLoading || presentcount.isLoading || statuscount.isLoading || newcount.isLoading || undefinedcount.isLoading || upcomingcount.isLoading || bellleads.isLoading || attendancestatus.isLoading){
    return <Alert severity="info" sx={{ mt: 0 }}>Please wait..,</Alert>
  }
  if(totalleadcount.isError || nonassigncount.isError || assigncount.isError || missedcount.isError || presentcount.isError || statuscount.isError || newcount.isError || undefinedcount.isError || upcomingcount.isError || bellleads.isLoading || attendancestatus.isLoading){
    return <Alert severity="info" sx={{ mt: 0 }}>Unable to fetch data, check after sometime</Alert>
  }

  const LeadSubMenu = Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin' ?
    [
      { icon: <CheckBoxIcon />, SubMenuName: "Add Lead", Link: "/add-lead"},
      { icon: <CheckBoxIcon />, SubMenuName: "Total Leads", Link: "/total-leads", lcount:totalleadcount.data ? totalleadcount.data : 0 },
      { icon: <CheckBoxIcon />, SubMenuName: "Leads By Status", Link: "/all-status"},
      { icon: <CheckBoxIcon />, SubMenuName: "Non Assign Leads", Link: "/nonassignleads", lcount:nonassigncount.data ? nonassigncount.data : 0},
      { icon: <CheckBoxIcon />, SubMenuName: "Assign Leads", Link: "/assignleads", lcount:assigncount.data ? assigncount.data : 0},
      { icon: <CheckBoxIcon />, SubMenuName: "Present Leads ", Link: "/todaysleads", lcount:presentcount.data ? presentcount.data : 0},
      { icon: <CheckBoxIcon />, SubMenuName: "Upcoming Leads", Link: "/upcomingleads", lcount:upcomingcount.data ? upcomingcount.data : 0},
      { icon: <CheckBoxIcon />, SubMenuName: "Missed Leads", Link: "/missedleads", lcount:missedcount.data ? missedcount.data : 0},
      { icon: <CheckBoxIcon />, SubMenuName: "Leads Reports", Link: "/leadsreports"},
    ]
  :
    [
      { icon: <CheckBoxIcon />, SubMenuName: "Total Leads", Link: "/total-leads", lcount:totalleadcount.data ? totalleadcount.data : 0},
      { icon: <CheckBoxIcon />, SubMenuName: "Leads By Status", Link: "/all-status"},
      { icon: <CheckBoxIcon />, SubMenuName: "Non Assign Leads", Link: "/nonassignleads", lcount:nonassigncount.data ? nonassigncount.data : 0},
      // { icon: <CheckBoxIcon />, SubMenuName: "Assign Leads", Link: "/assignleads", lcount:assigncount.data},
      { icon: <CheckBoxIcon />, SubMenuName: "Present Leads ", Link: "/todaysleads", lcount:presentcount.data ? presentcount.data : 0},
      { icon: <CheckBoxIcon />, SubMenuName: "Upcoming Leads", Link: "/upcomingleads", lcount:upcomingcount.data ? upcomingcount.data : 0},
      { icon: <CheckBoxIcon />, SubMenuName: "Missed Leads", Link: "/missedleads", lcount:missedcount.data ? missedcount.data : 0},
      // { icon: <CheckBoxIcon />, SubMenuName: "Leads Reports", Link: "/leadsreports"},
    ]
  ;
  const UserSubMenu = [
    { icon: <CheckBoxIcon />, SubMenuName: "User Leads", Link: "/userleads" },
    { icon: <CheckBoxIcon />, SubMenuName: "All Users", Link: "/all-users" },
    { icon: <CheckBoxIcon />, SubMenuName: "Add User", Link: "/add-user"},
  ]
  const DynamicFieldsSubMenu = [
    // { icon: <CheckBoxIcon />, SubMenuName: "Geolocation", Link: "/geolocation" },
    { icon: <CheckBoxIcon />, SubMenuName: "Project Name", Link: "/project-name" },
    { icon: <CheckBoxIcon />, SubMenuName: "Source", Link: "/source"},
    { icon: <CheckBoxIcon />, SubMenuName: "Configuration", Link: "/configuration" },
    { icon: <CheckBoxIcon />, SubMenuName: "Leads Status", Link: "/lead-status" },
  ]

  let SideMenu = [];

   if(Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin'){
    SideMenu = [
      {
        icon: <DashboardIcon />,
        MenuName: "Dashboard",
        Link: "/dashboard",
        submenu: "No",
        MapFunction: "",
        setFunction: "",
        onclickFunction: ''
      },
      {
        icon: <SwitchAccountIcon />,
        MenuName: "Leads",
        Link: null,
        submenu: "Yes",
        MapFunction: LeadSubMenu,
        setFunction: ShowLead,
        onclickFunction: handleClickLead,
      },
      { 
        icon: <ManageAccountsIcon />,
        MenuName: "Users",
        Link: null,
        submenu: "Yes",
        MapFunction: UserSubMenu,
        setFunction: ShowUser,
        onclickFunction: handleClickUser,
      },
      {
        icon: <AccountTreeIcon />,
        MenuName: "Dynamic Fields",
        Link: null,
        submenu: "Yes",
        MapFunction: DynamicFieldsSubMenu,
        setFunction: ShowDynamicField,
        onclickFunction: handleClickDynamicField,
      },
      {
        icon: <DisplaySettingsIcon />,
        MenuName: "Change Password",
        Link: "/change-password",
        submenu: "No",
        MapFunction: "",
        setFunction: "",
        onclickFunction: "",
      },
      // {
      //   icon: <AssessmentIcon />,
      //   MenuName: "Reports",
      //   Link: "/reports",
      //   submenu: "No",
      //   MapFunction: "",
      //   setFunction: "",
      //   onclickFunction: "",
      // },
      // {
      //   icon: <AppRegistrationIcon />,
      //   MenuName: "CP Registration",
      //   Link: "/",
      //   submenu: "No",
      //   MapFunction: "",
      //   setFunction: "",
      //   onclickFunction: "",
      // },
      // {
      //   icon: <HomeWorkIcon />,
      //   MenuName: "Properties",
      //   Link: "/properties",
      //   submenu: "No",
      //   MapFunction: "",
      //   setFunction: "",
      //   onclickFunction: "",
      // },
      // {
      //   icon: <ApartmentIcon />,
      //   MenuName: "Projects",
      //   Link: "/projects",
      //   submenu: "No",
      //   MapFunction: "",
      //   setFunction: "",
      //   onclickFunction: "",
      // },
      // {
      //   icon: <ChaletIcon />,
      //   MenuName: "Post Rental",
      //   Link: "/post-rental",
      //   submenu: "No",
      //   MapFunction: "",
      //   setFunction: "",
      // },
      // {
      //   icon: <CurrencyRupeeIcon />,
      //   MenuName: "Pay Slip",
      //   Link: "/pay-slip",
      //   submenu: "No",
      //   MapFunction: "",
      //   setFunction: "",
      //   onclickFunction: "",
      // },
      // {
      //   icon: <SettingsInputCompositeIcon />,
      //   MenuName: "API Integration",
      //   Link: "/",
      //   submenu: "No",
      //   MapFunction: "",
      //   setFunction: "",
      //   onclickFunction: "",
      // },
    ];

   }else{
    SideMenu = [
      {
        icon: <DashboardIcon />,
        MenuName: "Dashboard",
        Link: "/dashboard",
        submenu: "No",
        MapFunction: "",
        setFunction: "",
        onclickFunction: "",
      },
      {
        icon: <SwitchAccountIcon />,
        MenuName: "Leads",
        Link: null,
        submenu: "Yes",
        MapFunction: LeadSubMenu,
        setFunction: ShowLead,
        onclickFunction: handleClickLead,
      },
      // {
      //   icon: <AssessmentIcon />,
      //   MenuName: "Reports",
      //   Link: "/reports",
      //   submenu: "No",
      //   MapFunction: "",
      //   setFunction: "",
      //   onclickFunction: "",
      // },
      // {
      //   icon: <AppRegistrationIcon />,
      //   MenuName: "CP Registration",
      //   Link: "/",
      //   submenu: "No",
      //   MapFunction: "",
      //   setFunction: "",
      //   onclickFunction: "",
      // },
      // {
      //   icon: <HomeWorkIcon />,
      //   MenuName: "Properties",
      //   Link: "/properties",
      //   submenu: "No",
      //   MapFunction: "",
      //   setFunction: "",
      //   onclickFunction: "",
      // },
      // {
      //   icon: <ApartmentIcon />,
      //   MenuName: "Projects",
      //   Link: "/projects",
      //   submenu: "No",
      //   MapFunction: "",
      //   setFunction: "",
      //   onclickFunction: "",
      // },
      // {
      //   icon: <ChaletIcon />,
      //   MenuName: "Post Rental",
      //   Link: "/post-rental",
      //   submenu: "No",
      //   MapFunction: "",
      //   setFunction: "",
      // },
      // {
      //   icon: <CurrencyRupeeIcon />,
      //   MenuName: "Pay Slip",
      //   Link: "/pay-slip",
      //   submenu: "No",
      //   MapFunction: "",
      //   setFunction: "",
      //   onclickFunction: "",
      // },
      // {
      //   icon: <SettingsInputCompositeIcon />,
      //   MenuName: "API Integration",
      //   Link: "/",
      //   submenu: "No",
      //   MapFunction: "",
      //   setFunction: "",
      //   onclickFunction: "",
      // },
    ];
   }

  function clearLocalStorage (){
    return localStorage.setItem('tablePageIndex', 0);
  }
   
  const notify = (msg) => toast(msg, {
    position: "bottom-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
  
  return (
    window.location.pathname === '' || window.location.pathname === '/' ?
      <Navigate to="/login" component={NavLink} replace={true} />
    : <>
      <CssBaseline />
      <Fade in direction='up' timeout={800}>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 3,
              ...(open && { display: "none" }),
            }}
          >
            <img src={Icon} alt="logo" style={{ width: "30px" }} />
            <MenuIcon sx={{ ml: 3 }} />
          </IconButton>
          CRM ID: 2110130043
          {/* <Search className="searchbar">
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
          </Search> */}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="From"
                inputFormat="MM/DD/YYYY"
                value={ufdate}
                onChange={ufdateChange}
                renderInput={(params) => <TextField {...params} />}
                sx={{p: 10}}
                className="fromdate"
              />
              &nbsp;
              <DesktopDatePicker
                label="To"
                inputFormat="MM/DD/YYYY"
                value={utdate}
                onChange={utdateChange}
                renderInput={(params) => <TextField {...params} />}
                sx={{p: 10}}
                className="fromdate"
              />
            </LocalizationProvider> */}

            {/* <Tooltip title="CRM Expiry Date." >
              <span style={{paddingTop: '1rem'}}></span>
            </Tooltip> */}

            <Tooltip title="Attendance">
              <IconButton
                aria-label="Attendance"
                color="inherit"
                size="large"
                sx={{ ml: 2 }}
                onClick={()=>setOpenModal(true)}
              >
                <FingerprintIcon
                  sx={{ color: attendancestatus.data.data === "In Time" ? "green" : "red" }}
                />
              </IconButton>
            </Tooltip>
 
            <Modal
              sx={{ "& .MuiDialog-paper": { width: "100%", maxHeight: 435 } }}
              maxWidth="xs"
              open={openModal}
              // onClose={()=>setOpenModal(false)}
            >
              <Box sx={style}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography variant="h6" component="h2" className="page-title">Attendance</Typography>
                  <IconButton aria-label="delete" onClick={()=>setOpenModal(false)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Box component="form" onSubmit={UserAttendance}>
                  {/* <input type="file" value={url}  name="user"/> */}
                  {!url && (
                    <React.Fragment>
                      <Webcam
                        ref={webcamRef}
                        audio={false}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                        onUserMedia={onUserMedia}
                        className="user-attendance-img"
                      />
                      <Box sx={{ textAlign: "center" }}>
                        <Button variant="outlined" onClick={capturePhoto}>Capture</Button>
                      </Box>
                    </React.Fragment>
                  )}
                  {url && (
                    <React.Fragment>
                      <img src={url} alt="Screenshot" className="user-attendance-img"
                      />
                      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Button variant="outlined" onClick={() => setUrl(null)}>Retake</Button>
                        <Button variant="outlined" type="submit">{attendancestatus.data.data === "In Time" ? "Out Time" : "In Time"}</Button>
                      </Box>
                    </React.Fragment>
                  )}
                </Box>
              </Box>
            </Modal>

            {/* <Tooltip title="Filter">
              <IconButton
                aria-label="Filter"
                color="inherit"
                size="large"
                sx={{ ml: 2 }}
              >
                <TuneIcon />
              </IconButton>
            </Tooltip> */}
            {/* <Tooltip
              title={
                <React.Fragment>
                  <span>Remaining: 300 Day's</span>
                  <Divider sx={{ my: "5px", borderColor: "white" }} />
                  <span>Expire On: 31-12-2023</span>
                </React.Fragment>
              }
            >
              <IconButton
                aria-label="Expires"
                color="inherit"
                size="large"
                sx={{ ml: 2 }}
              >
                <PrivacyTipOutlinedIcon />
              </IconButton>
            </Tooltip> */}

            <Tooltip title="Notifications">
              <IconButton
                aria-label="show 1 new notifications"
                color="inherit"
                onClick={notifyProfileMenuOpen}
                onMouseOver={notifyProfileMenuOpen}
                size="large"
                sx={{ ml: 2 }}
                aria-controls={isNotifyMenuOpen ? "notifyMenuId" : undefined}
                aria-haspopup="true"
                aria-expanded={isNotifyMenuOpen ? "true" : undefined}
              >
                <Badge badgeContent={presentcount.data} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleProfileMenuOpen}
                onMouseOver={handleProfileMenuOpen}
                size="large"
                color="inherit"
                sx={{ ml: 2 }}
                aria-controls={isMenuOpen ? "menuId" : undefined}
                aria-haspopup="true"
                aria-expanded={isMenuOpen ? "true" : undefined}
              >
                <img src={User} alt="user" className="userimg" />
                <span className="title">{Cookies.get('name')}</span>
                <KeyboardArrowDownIcon sx={{ fontSize: "18px", mt: "-2px" }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>
      </Fade>
      {renderMenu}
      {/* prasent leads drop down */}
      <Menu
        anchorEl={notifyEl}
        id="notifyMenuId"
        open={isNotifyMenuOpen}
        onClose={notifyMenuClose}
        onClick={notifyMenuClose}
        MenuListProps={{ onMouseLeave: notifyMenuClose }}
        className="dropdown"
        PaperProps={{
          elevation: 0,
          sx: {
            width: "250px",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: -0.8,
            "& .MuiAvatar-root": {
              width: 42,
              height: 42,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {bellleads.data.data !== 'No Data Found' ?
          bellleads.data.data.map((row, i) => {
            return (
              <Box key={i}>
                <ListItem disableGutters key={i} >
                  <ListItemButton component={NavLink} to="todaysleads" >
                    <ListItemAvatar>
                      <Avatar >
                        <PersonIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={row.lname} 
                      secondary={'Today At '+ dayjs(row.followup_dt).format("hh:mm A")}
                    />
                  </ListItemButton>
                </ListItem>
                <Divider /> 
              </Box>
            );
          })
        : 
          <ListItem button>
            <ListItemAvatar>
              <Avatar>
                <CloseIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="" secondary="No Data Found" />
          </ListItem>
        } 
        <Divider /> 
        <Box sx={{mx:2 , my:1, textAlign:"center"}}>
          <Typography component={NavLink} to="todaysleads" variant="h2" className="page-title">
            View All
          </Typography>
        </Box>
      </Menu>

      <Fade in direction='up' timeout={800}>
      <Drawer variant="permanent" open={open} className="leftsidenav">
        <DrawerHeader sx={{ p: 0 }}>
          <ListItem disablePadding sx={{ display: "block" }}>
            {open ? (
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 1 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img src={Logo} alt="logo" style={{ width: "50px" }} />
                </ListItemIcon>
                <ListItemText primary={Cookies.get('name')} secondary={Cookies.get('role')} className="user-details" />
              </ListItemButton>
            ) : null}
          </ListItem>
          <IconButton
            onClick={handleDrawerClose}
            sx={{ position: "absolute", top: "0", right: "0" }}
          >
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <List>
          {SideMenu.map((Menu, id) => {
            return (
              <ListItem
                onMouseOver={handleDrawerOpen}
                key={id}
                disablePadding
                sx={{ display: "block" }}
                onClick={() => clearLocalStorage()}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={Menu.Link === null ? Menu.onclickFunction : null}
                  component={Menu.Link === null ? "div" : NavLink}
                  to={Menu.Link === null ? "" : Menu.Link}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {Menu.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={Menu.MenuName}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                  {open ? (
                    <>
                      {Menu.submenu === "Yes" ? (
                        <>
                          {Menu.setFunction ? <KeyboardArrowDownIcon /> : <ChevronLeftIcon />}
                        </>
                      ) : null}
                    </>
                  ) : null}
                </ListItemButton>
                {
                  Menu.submenu === "Yes" ? (
                    <>
                      <Collapse timeout="auto" in={Menu.setFunction} unmountOnExit>
                        {Menu.MapFunction.map((row, subid) => {
                          return (
                            <Fade in key={subid} direction='up' timeout={800}>
                            <div >
                              <List component="div" disablePadding >
                                <ListItemButton
                                  sx={{ pl: open ? 5 : 2.5 }}
                                  component={NavLink}
                                  to={row.Link}
                                >
                                  <ListItemIcon
                                    sx={{
                                      minWidth: 0,
                                      mr: open ? 1 : "auto",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {row.icon}
                                  </ListItemIcon>
                                  {open ? (
                                  <>
                                    <ListItemText primary={row.SubMenuName} />
                                    {row.lcount ? <Chip label={row.lcount} variant="outlined" size="small" sx={{position: 'absolute', right: '0.8rem', borderRadius: '5px', border: '1px solid #6d768a', py: 0.2}} /> : ''}
                                  </>
                                    
                                  ) : null}
                                </ListItemButton>
                              </List>
                              <Divider />
                            </div>
                            </Fade>
                          );
                        })}
                      </Collapse>
                    </>
                  ) : null
                }
              </ListItem>
            );
          })}
        </List>
      </Drawer >
      </Fade>
      <Box component="main" className={'main'}>
        <DrawerHeader className="test" />
        <Outlet />
      </Box>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  )
}