import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import EventBusyRoundedIcon from '@mui/icons-material/EventBusyRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import CurrencyRupeeRoundedIcon from '@mui/icons-material/CurrencyRupeeRounded';
import FlareIcon from '@mui/icons-material/Flare';
import HomeWorkIcon from '@mui/icons-material/HomeWork';


const LeadStatus = (props) => {
  return (
    <Box className="cstbox" >
      <h5 className="ltitle" style={{ marginBottom: '10px' }}>Leads Status</h5>
      <List sx={{ height: '360px', overflowY: 'scroll' }}>
        {console.log(props)}
        {props.data !== 'No Data Found' ? props.data.map((row, index) => {
          return (
            <ListItem key={index}>
              <ListItemAvatar>
                <Box
                  sx={{
                    width: '35px',
                    height: '35px',
                    borderRadius: '2px',
                    opacity: '.8',
                    boxShadow: `${'0 0 2px 0 ' + row.color}`,
                    backgroundColor: `${row.color}`,
                    '&:hover': {
                      opacity: [0.9, 0.8, 0.7],
                    },
                  }}
                />
              </ListItemAvatar>
              <ListItemText primary={row.status} secondary={'Total: ' + row.leadstatus_count} />
            </ListItem>
          )
        }) : ''}
      </List>
    </Box>
  )
}

export default LeadStatus;