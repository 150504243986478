import React, { Suspense } from 'react';
import { Link } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './Pages/Login';
import Error from './Pages/Error';
import CRMLogo from './favicon.ico';

import { onMessageListener, messaging } from "./firebase";
import { getToken } from "firebase/messaging";

// mobile
import Leads from './Pages/Leads/Leads';
import FollowUp from './Pages/FollowUp/FollowUp';
import Account from './Pages/Account/Account';
import ViewLead from './Pages/ViewLead/Viewlead';
import Status from './Pages/Status/Status';
import AddLead from './Pages/AddLead/AddLead';
import EditLead from './Pages/EditLead/EditLead';
import MissedLead from './Pages/MissedLead/MissedLead';
import NewLead from './Pages/NewLead/NewLead';
import UndefinedLead from './Pages/UndefinedLead/UndefinedLead';
import StatusLead from './Pages/StatusLead/StatusLead';
import UpcomingLead from './Pages/UpcomingLead/UpcomingLead';
import SomedayLead from './Pages/SomedayLead/SomedayLead';
import MultiSelect from './Pages/MultiSelect/MultiSelect'; 
// import EditProfile from './Pages/EditProfile/EditProfile';
import SearchLead from './Pages/Search/Search';
import Footer from './Components/Footer/Footer';
// ends
import Alert from '@mui/material/Alert';
import axios from 'axios';
import './App.css';
// pre-catching
import {QueryClientProvider, QueryClient} from "react-query";

// server api
//export const API_URL = "http://localhost:3004";
// export const API_URL = "https://node.rabs.support";
// export const API_URL = "https://backend.newcrm.propertycloud.in";
//export const API_URL = "https://backendcrm.crm.housinghelp.in"
//export const API_URL = "https://backendcrm.newcrm.aadeerealty.com"
//export const API_URL = "https://crmmphrealtybackend.rabs.support"
export const API_URL = "https://backend.crm.jawaheraldar.com";

axios.defaults.withCredentials = true;

// routes
// const Login = lazy(() => import('./Pages/Login'));
// const Leads = lazy(() => import('./Pages/Leads/Leads'));
// const Error = lazy(() => import('./Pages/Error'));
// const ViewLead = lazy(() => import('./Pages/ViewLead/Viewlead'));
// const Status = lazy(() => import('./Pages/Status/Status'));
// const AddLead = lazy(() => import('./Pages/AddLead/AddLead'));
// const EditLead = lazy(() => import('./Pages/EditLead/EditLead'));
// const MissedLead = lazy(() => import('./Pages/MissedLead/MissedLead'));
// const NewLead = lazy(() => import('./Pages/NewLead/NewLead'));
// const StatusLead = lazy(() => import('./Pages/StatusLead/StatusLead'));
// const UpcomingLead = lazy(() => import('./Pages/UpcomingLead/UpcomingLead'));
// const SomedayLead = lazy(() => import('./Pages/SomedayLead/SomedayLead'));
// const FollowUp = lazy(() => import('./Pages/FollowUp/FollowUp'));
// const Account = lazy(() => import('./Pages/Account/Account'));
// const EditProfile = lazy(() => import('./Pages/EditProfile/EditProfile'));

// pre-catching
export const queryClient = new QueryClient();

function App() {
  
  const [token, setToken] = React.useState("");
    const [notification, setNotification] = React.useState({ title: "", body: "" });

    const requestForToken = () => {
      return getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPID_KEY,
        // Vapid key of your firebase account
      })
      .then((currentToken) => {
        if (currentToken) {
          console.log("current token for client: ", currentToken);
          setToken(currentToken);
          // Perform any other necessary action with the token
        } else {
          // Show permission request UI
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });
    };

    React.useEffect(() => {
      if (!notification?.title) {
      requestForToken();
          // Upon initial loading, a request for the user's token is made
      } else {
      console.log("Background Message");
          // Show Notification based on any change on the website i.e. after the post request
      }
    }, []);

  onMessageListener()
  .then((payload) => {
    const { title, body } = payload.notification;
    // const { data: { icon } } = payload;
    setNotification({
      title: payload?.notification?.title,
      body: payload?.notification?.body,
    });
    new Notification(title, { body, icon: CRMLogo });
  })
  .catch((err) => console.log("failed: ", err));

  let href = window.location.href;
  let count = href.split("//").length - 1;

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        {window.location.pathname === "/" || count > 1 ? window.location.pathname = "login" : null}
        {window.location.pathname === "/dashboard" ? window.location.pathname = "/login" : null}
        {/* maxduration={0} */}
        <Suspense fallback={<Alert severity="info" sx={{ mt: 0 }}>Please wait..,</Alert>} >
          <Routes>
            <Route path="/" element={<Footer />}>
              <Route path="leads" element={<Leads />} />
              <Route path="followup" element={<FollowUp />} />
              <Route path="account" element={<Account token={token}/>} />
              <Route path="error" element={<Error />} />
              <Route path="*" element={<Navigate component={Link} replace to="login" />} />
              <Route path="status" element={<Status />} />
            </Route>
            <Route path="login" element={<Login token={token} />} />
            <Route path="addlead" element={<AddLead />} />
            <Route path="editlead/:leadId" element={<EditLead />} />
            <Route path="viewlead/:leadId" element={<ViewLead />} />
            <Route path="missedlead" element={<MissedLead />} />
            <Route path="newlead" element={<NewLead />} />
            <Route path="undefinedlead" element={<UndefinedLead />} />
            <Route path="statuslead/:leadStatus" element={<StatusLead />} />
            <Route path="upcominglead" element={<UpcomingLead />} />
            <Route path="somedaylead" element={<SomedayLead />} />
            {/* <Route path="editprofile" element={<EditProfile />} />  */}
            <Route path="search" element={<SearchLead />} />
            <Route path="multiselect" element={<MultiSelect />} />
          </Routes>
        </Suspense>
      </Router>
    </QueryClientProvider>
  );
}

export default App;