import React, { Suspense } from 'react';
import { Link } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './Pages/Login';
import Error from './Pages/Error'; 
import CRMLogo from './favicon.ico';

import { onMessageListener, messaging } from "./firebase";
import { getToken } from "firebase/messaging";

// desktop
import LeftSideNav from './Desktop/Components/LeftSideNav/LeftSideNav';
import Dashboard from './Desktop/Pages/Dashboard';
import TotalLeads from './Desktop/Pages/TotalLeads/TotalLeads';
import AddLead from './Desktop/Pages/AddLead/AddLead';
import EditLead from './Desktop/Pages/EditLead/EditLead';
import QuickEdit from './Desktop/Pages/QuickEdit/QuickEdit';
import ViewLead from './Desktop/Pages/ViewLead/ViewLead';
import LeadsByStatus from './Desktop/Pages/LeadsByStatus/LeadsByStatus';
import AssignLeadForm from './Desktop/Pages/AssignLeadForm/AssignLeadForm';
import AllUsers from './Desktop/Pages/AllUsers/AllUsers';
import AddUser from './Desktop/Pages/AddUser/AddUser';
import UserTable from './Desktop/Pages/AllUsers/AllUsersTable'; 
import EditUsers from './Desktop/Pages/AllUsers/EditUsers';
import Geolocation from './Desktop/Pages/DynamicFields/Geolocation/Geolocation';
import ProjectName from './Desktop/Pages/DynamicFields/ProjectName/ProjectName';
import Source from './Desktop/Pages/DynamicFields/Source/Source';
import Configuration from './Desktop/Pages/DynamicFields/Configuration/Configuration';
import LeadStatus from './Desktop/Pages/DynamicFields/LeadStatus/LeadStatus';
import AllStatus from './Desktop/Pages/Status/AllStatus';
import ChangePassword from './Desktop/Pages/ChangePassword/ChangePassword';
import UserLeads from './Desktop/Pages/UsersLeads/UserLeads';
// ends
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import axios from 'axios';

// pre-catching
import { QueryClientProvider, QueryClient } from "react-query";
import './Desktop/Pages/Common.css';
import LeadsReports from './Desktop/Pages/LeadsReports/LeadsReports';
import MissedLeads from './Desktop/Pages/MissedLeads/Missedleads';
import TodaysLeads from './Desktop/Pages/TodaysLeads/Todaysleads';
import AssignLeads from './Desktop/Pages/AssignLeads/AssignLeads';
import NonAssignLeads from './Desktop/Pages/NonAssignLeads/NonAssignLeads';
import UpcomingLeads from './Desktop/Pages/UpcomingLeads/UpcomingLeads'; 
import Search from './Desktop/Pages/AdvanceSearch/Search';

// server api
//export const API_URL = "http://localhost:3004";
//export const API_URL = "https://node.rabs.support";
// export const API_URL = "https://backend.newcrm.propertycloud.in";
//export const API_URL = "https://backendcrm.crm.housinghelp.in"
//export const API_URL = "https://backendcrm.newcrm.aadeerealty.com"
//export const API_URL = "https://crmmphrealtybackend.rabs.support"
export const API_URL = "https://backend.crm.jawaheraldar.com";

axios.defaults.withCredentials = true;

// pre-catching
export const queryClient = new QueryClient();
 
function DesktopApp() { 

    const [token, setToken] = React.useState("");
    const [notification, setNotification] = React.useState({ title: "", body: "" });

    const requestForToken = () => {
      return getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPID_KEY,
        // Vapid key of your firebase account
      })
      .then((currentToken) => {
        if (currentToken) {
          console.log("current token for client: ", currentToken);
          setToken(currentToken);
          // Perform any other necessary action with the token
        } else {
          // Show permission request UI
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });
    };

    React.useEffect(() => {
        if (!notification?.title) {
        requestForToken();
            // Upon initial loading, a request for the user's token is made
        } else {
        console.log("Background Message");
            // Show Notification based on any change on the website i.e. after the post request
        }
    }, []);

    onMessageListener()
    .then((payload) => {
      const { title, body } = payload.notification;
      // const { data: { icon } } = payload;
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
      new Notification(title, { body, icon: CRMLogo });
    })
    .catch((err) => console.log("failed: ", err));

    return (
        <QueryClientProvider client={queryClient}>
            <Box sx={{ display: 'flex' }}>
                <Router> 
                    {window.location.pathname === "/leads" ? window.location.pathname = "/login" : null}
                    <Suspense fallback={<Alert severity="info" sx={{ mt: 0 }}>Please wait..,</Alert>} >
                        <Routes>
                            <Route path="login" element={<Login token={token} />} />
                            <Route path="*" element={<Navigate component={Link} to="login" />} />
                            <Route path="/" element={<LeftSideNav token={token}/>} >
                                <Route path="dashboard" element={<Dashboard />} /> 
                                <Route path="error" element={<Error />} />
                                <Route path="total-leads" element={<TotalLeads />} />
                                <Route path="total-leads/:pageId" element={<TotalLeads />} />
                                <Route path="add-lead" element={<AddLead />} />
                                <Route path="add-lead/:lastlocation" element={<AddLead />} />
                                <Route path="edit-lead/:leadId/:pageId" element={<EditLead />} />
                                <Route path="edit-lead/:leadId/:pageId/:lastlocation" element={<EditLead />} />
                                <Route path="quick-edit/:leadId/:pageId" element={<QuickEdit />} />
                                <Route path="quick-edit/:leadId/:pageId/:lastlocation" element={<QuickEdit />} />
                                <Route path="view-lead/:leadId/:pageId" element={<ViewLead />} />
                                <Route path="view-lead/:leadId/:pageId/:lastlocation" element={<ViewLead />} />
                                <Route path="assign-leads-to/:leadId/:pageId/:page" element={<AssignLeadForm />} />
                                <Route path="assign-leads-to/:leadId/:pageId/:page/:lastlocation" element={<AssignLeadForm />} />
                                <Route path="leads-by-status/:status/" element={<LeadsByStatus />} />
                                <Route path="all-users" element={<AllUsers />} />
                                <Route path="add-user" element={<AddUser />} />
                                <Route path="edit-user/:user" element={<EditUsers/>}/>
                                <Route path="edit-user/:user/:lastlocation" element={<EditUsers/>}/>
                                <Route path="userleads" element={<UserLeads/>}/>
                                <Route path="geolocation" element={<Geolocation />} />
                                <Route path="project-name" element={<ProjectName />} />
                                <Route path="source" element={<Source />} />
                                <Route path="configuration" element={<Configuration />} />
                                <Route path="lead-status" element={<LeadStatus />} />
                                <Route path="all-status" element={<AllStatus/>}/>
                                <Route path="change-password" element={<ChangePassword />} />  
                                <Route path="leadsreports" element={<LeadsReports/>}/>
                                <Route path="missedleads" element={<MissedLeads/>}/>
                                <Route path="todaysleads" element={<TodaysLeads/>}/>
                                <Route path="assignleads" element={<AssignLeads/>}/>
                                <Route path="assignleads/:lastlocation" element={<AssignLeads/>}/>
                                <Route path="nonassignleads" element={<NonAssignLeads/>}/>
                                <Route path="nonassignleads/:lastlocation" element={<NonAssignLeads/>}/>
                                <Route path="upcomingleads" element={<UpcomingLeads/>}/>
                                <Route path="upcomingleads/:lastlocation" element={<UpcomingLeads/>}/>
                                <Route path="search" element={<Search/>}/>
                                <Route path="users/:role" element={<UserTable/>}/> 
                            </Route> 
                        </Routes> 
                    </Suspense>  
                </Router>
            </Box>
        </QueryClientProvider>
    );
}

export default DesktopApp;