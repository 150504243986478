import Axios from 'axios';
import { API_URL } from '../../../DesktopApp';
import React from 'react'

const getLeads = async(LIMIT, page) => {
    try{
      const response = await Axios.post(`${API_URL}/desktop/leads/getAllLeads`,{limit: LIMIT, page: page});
      return response;
    }catch(err){
        return err;
    }
}

const getExportLeads = async() => {
  try{
    const response = await Axios.post(`${API_URL}/desktop/leads/getAllExportLeads`,);
    return response;
  }catch(err){
      return err;
  }
}

const getExportSearchResult = async(data, createdtfrom, createdtto, followupdtfrom, followupdtto) => {
  try{
    const response = await Axios.post(`${API_URL}/desktop/search/getAllExportSearchResult`, {
     
      source: data.get('source'),
      service_type: data.get('service_type'),
      pname : data.get('pname'),
      country : data.get('country'),
      state : data.get('state'),
      city : data.get('city'),
      locality : data.get('locality'),
      ptype : data.get('ptype'),
      pcategory : data.get('pcategory'),
      pconfiguration : data.get('configuration'),
      status : data.get('status'),
      anytext : data.get('anytext'),
      createdtfrom : createdtfrom,
      createdtto : createdtto,
      followupdtfrom : followupdtfrom,
      followupdtto : followupdtto,
      sales_manager : data.get('sales_manager'),
      tele_caller : data.get('tele_caller'),
      team_leader : data.get('team_leader'),
      branch_admin : data.get('branch_admin'),
    });
    return response;
  }catch(err){
      return err;
  }
};

const getSearchResult = async(LIMIT, page, data, createdtfrom, createdtto, followupdtfrom, followupdtto) => {
  try{
    const response = await Axios.post(`${API_URL}/desktop/search/getSearchResult`, {
      limit: LIMIT, 
      page: page,
      source: data.get('source'),
      service_type: data.get('service_type'),
      pname : data.get('pname'),
      country : data.get('country'),
      state : data.get('state'),
      city : data.get('city'),
      locality : data.get('locality'),
      ptype : data.get('ptype'),
      pcategory : data.get('pcategory'),
      pconfiguration : data.get('configuration'),
      status : data.get('status'),
      anytext : data.get('anytext'),
      createdtfrom : createdtfrom,
      createdtto : createdtto,
      followupdtfrom : followupdtfrom,
      followupdtto : followupdtto,
      sales_manager : data.get('sales_manager'),
      tele_caller : data.get('tele_caller'),
      team_leader : data.get('team_leader'),
      branch_admin : data.get('branch_admin'),
    });
    return response;
  }catch(err){
      return err;
  }
};

const getSearchResultCount = async(data, createdtfrom, createdtto, followupdtfrom, followupdtto) => {
  try{
    const response = await Axios.post(`${API_URL}/desktop/search/getSearchResultCount`, {
      source: data.get('source'),
      service_type: data.get('service_type'),
      pname : data.get('pname'),
      country : data.get('country'),
      state : data.get('state'),
      city : data.get('city'),
      locality : data.get('locality'),
      ptype : data.get('ptype'),
      pcategory : data.get('pcategory'),
      pconfiguration : data.get('configuration'),
      status : data.get('status'),
      anytext : data.get('anytext'),
      createdtfrom : createdtfrom,
      createdtto : createdtto,
      followupdtfrom : followupdtfrom,
      followupdtto : followupdtto,
      sales_manager : data.get('sales_manager'),
      tele_caller : data.get('tele_caller'),
      team_leader : data.get('team_leader'),
      branch_admin : data.get('branch_admin'),
    });
    return response;
  }catch(err){
      return err;
  }
};

const unSetLead = async(lead_id) => {
  try{
    const response = await Axios.post(`${API_URL}/leads/deletelead`, {
      lid: lead_id,
    });
    return response;
  }catch(err){
      return err;
  }
};

function groupBy(objectArray, property) {
  return objectArray.reduce((acc, obj) => {
     const key = obj[property];
     if (!acc[key]) {
        acc[key] = [];
     }
     acc[key].push(obj);
     return acc;
  }, {});
}

const getDropdown = async() => {
  try{
    const source = await Axios.post(`${API_URL}/desktop/searchdropdown/getSource`);
    const project = await Axios.post(`${API_URL}/desktop/searchdropdown/getProject`);
    const configuration = await Axios.post(`${API_URL}/desktop/searchdropdown/getConfiguration`);
    const status = await Axios.post(`${API_URL}/desktop/searchdropdown/getStatus`);
    const usersList = await Axios.post(`${API_URL}/users/getUserList`);
    const locality = await Axios.post(`${API_URL}/desktop/searchdropdown/getLocality`);
    const users = usersList.data!== 'No Data Found' ? groupBy(usersList.data.map((users)=>({label: users.username, value: users.u_id, urole:users.urole})), 'urole') : [];
    return {source, project, configuration, status, users, locality};
  }catch(err){
      return err;
  }
};

const setClick = async (lid) =>{
  try{
      const response = await Axios.post(`${API_URL}/leads/setclick`, {lid: lid});
      return response;
  }catch(err){
      return err;
  }
}

export {getLeads, getExportLeads, getExportSearchResult, unSetLead, getSearchResult, getSearchResultCount, getDropdown, setClick}