import React, { useEffect, useState } from 'react'; 
import { NavLink, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
// import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles'; 
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment'; 
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ListItem from '@mui/material/ListItem'; 
import Logoimg from './logo192.png';
import Loginbg from './login-bg.jpg';
// import Axios from 'axios';
import { setLogin, getLoginCheck } from './ApiCalls';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import Cookies from 'js-cookie';

// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       Powered By<br />
//       <Link color="inherit" href="https://rabsnetsolutions.com/" target={'blank'} sx={{textDecoration: 'none'}}>
//         RABS Net Solutions PVT. LTD.
//       </Link>
//     </Typography>
//   );
// }

const theme = createTheme();

export default function Login({token}) {

  const navigate = useNavigate();
  
  useEffect(() => {
    getLoginCheck().then(response => {
      if(response.data === 'Session found'){
        if(isMobile && MobileView){
          navigate('/leads');
        }else if(isBrowser && BrowserView){
          navigate('/dashboard');
        }
      }
    }).catch(err => {
      console.log(err);
    });
  });
 
  const [loginStatus, setLoginStatus] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  }; 
 
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setLogin(data, token).then(response => {
      console.log(response)
      if(response.data.status === "login done"){
        setLoginStatus('true');

        // Cookies.set('role', response.data.role, { domain: 'localhost', path: '/',  expires: 365 })
        // Cookies.set('name', response.data.name, { domain: 'localhost', path: '/',  expires: 365 })

        Cookies.set('role', response.data.role, { domain: 'frontend.crm.jawaheraldar.com', path: '/',  expires: 365 })
        Cookies.set('name', response.data.name, { domain: 'frontend.crm.jawaheraldar.com', path: '/',  expires: 365 })

        // Cookies.set('role', response.data.role, { domain: 'frontend.newcrm.propertycloud.in', path: '/', expires: 365 })
        // Cookies.set('name', response.data.name, { domain: 'frontend.newcrm.propertycloud.in', path: '/', expires: 365 })

        // Cookies.set('role', response.data.role, { domain: 'frontend.crm.housinghelp.in', path: '/',  expires: 365  })
        // Cookies.set('role', response.data.role, { domain: 'frontend.newcrm.aadeerealty.com', path: '/',  expires: 365  })
        // Cookies.set('role', response.data.role, { domain: 'crmmphrealty.rabs.support', path: '/',  expires: 365  })
        // Cookies.set('role', response.data.role, { domain: 'nodeclient.rabs.support', path: '/',  expires: 365  })
        // Cookies.set('name', response.data.name, { domain: 'nodeclient.rabs.support', path: '/',  expires: 365  })

        if(isMobile && MobileView){
          navigate('/leads');
        }else if(isBrowser && BrowserView){
          navigate('/dashboard');
        }

       }else{
        setLoginStatus('false')
       }
      console.log(response);
    }).catch(err => {
      console.log(err);
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh', overflow: 'hidden'}}>
        <CssBaseline />
        <Grid item px={{xs:0, sm:0, md:3}} xs={12} sm={8} md={4} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <ListItem button component={NavLink} to="/login" style={{justifyContent: 'center', textDecoration: 'none!important'}}><img src={Logoimg} alt='logo' style={{ width: '70%', height: 'auto' }} /></ListItem>
            <Typography color="inherit" component="h1" variant="h5" sx={{pt:1, px: 0}}>
              Login, to see it in action.
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField 
                margin="normal"
                type="text"
                id="username"
                label="Username"
                name="username"
                variant="standard"
                autoComplete="email"
                sx={{width:'100%'}}
                autoFocus
                required
              />
              <FormControl margin="normal" variant="standard" style={{ width: '100%', height: 'auto' }}>
                <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                <Input
                  id="standard-adornment-password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  autoComplete="current-password"
                  required
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                       {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 4, mb: 2 }}
              >
                Log In
              </Button> 

              {loginStatus === 'true' ? <Alert severity="success">User Verified</Alert> 
              : loginStatus === '' ? '' 
              : <Alert severity="error">Invalid Username or Password</Alert>}
              
              {/* <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid> */}
              {/* <Copyright sx={{ mt: 10 }} /> */}
            </Box>
          </Box>
        </Grid>
        <Grid item
          xs={false}
          sm={4}
          md={8}
          display={{ xs: "none", sm: "block" }}
        >
          <img src={Loginbg} alt='img' style={{ width: '100%', height: '100vh' }} />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}